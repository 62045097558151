export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0, user-scalable=no"},{"charset":"utf-8"},{"meta":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"}],"link":[{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","href":"https://fonts.gstatic.com","crossorigin":"anonymous"},{"rel":"prefetch","href":"https://labsoffice.b-cdn.net/assets/css/9a51f883-7fd4-4ff4-a71a-95e07a59e24d.css","crossorigin":true},{"rel":"stylesheet","href":"https://labsoffice.b-cdn.net/assets/css/9a51f883-7fd4-4ff4-a71a-95e07a59e24d.css","defer":true}],"style":[],"script":[],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1.0, user-scalable=no","bodyAttrs":{"class":""},"htmlAttrs":{"lang":"tr"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"